import type {Address, Cart, CartItem} from "~/models/cart";

export const useCartStore = defineStore(
    'cart',
    () => {
        const cart = ref<Cart | undefined>()
        const clientSecret = ref<string | undefined>()

        const reset = () => {
            cart.value = undefined
        }

        const updateDiscountCode = async (codes: string[]) => {
            // await refreshCart()
        }


        const addToCart = async (productId: string, variantId: string, currency: string, sellerHandle: string, quantity: number = 1) => {
            const {createCart} = useCart()
            if (!cart.value) {
                const newCart = await createCart(
                    productId,
                    variantId,
                    currency,
                    sellerHandle,
                    quantity
                )
                cart.value = newCart.value
            } else {
                cart.value.items = cart.value.items || []
                cart.value = (
                    await useCart().updateCart(
                        cart.value.id,
                        cart.value.items.concat([{
                            product_id: productId,
                            variant_id: variantId,
                            quantity,
                            shop_handle: sellerHandle
                        } as CartItem])
                    )
                ) || cart.value
            }
        }

        const removeFromCart = async (itemId: string) => {
            if (!cart.value) return
            cart.value = (await useCart().updateCart(
                cart.value.id,
                cart.value.items.filter(item => item.item_id !== itemId)
            )) || cart.value
        }
        const updateLineQuantity = async (lineId: string, quantity: number) => {
            if (!cart.value) return
            const newItems = cart.value.items.map(item => {
                if (item.item_id === lineId) {
                    return {
                        ...item,
                        quantity
                    }
                }
                return item
            })
            cart.value = (await useCart().updateCart(cart.value.id, newItems)) || cart.value
        }


        const getCart = async (cartId: string) => {
            cart.value = (await useCart().getCart(cartId)) || undefined
        }

        const refreshCart = async () => {
            if (!cart.value) return
            await getCart(cart.value.id)
        }

        const validatePhone = async (email: string, phone: string) => {
            const {error: phoneCheckError} = await useApiFetch(
                `/public/phone_taken`,
                {
                    method: 'GET',
                    params: {
                        email: email,
                        phone: phone
                    }
                })
            if (phoneCheckError.value && phoneCheckError.value.statusCode === 409) {
                return false
            } else if (phoneCheckError.value) {
                console.error("Another error occurred while checking phone number, ", phoneCheckError.value)
            }
            return true
        }


        const discount = computed(() => {
            if (!cart.value || !cart.value.discount) return '';
            return useFormatMoney(cart.value.discount);
        });

        const subTotal = computed(() => {
            if (!cart.value) return '';

            const subtotal = cart.value.items.reduce((total, item) => total + item.price.amount * item.quantity, 0);
            return useFormatMoney({amount: subtotal, currency: cart.value.total.currency})
        });

        const total = computed(() => {
            if (!cart.value) return '';
            return useFormatMoney(cart.value.total)
        });

        const deliveryAddress = computed(
                (): Address | null => {
                    if (!cart.value) return null;
                    return cart.value.shipping_address as Address;
                }
            )
        ;

        const cartCurrency = computed(() => {
            if (!cart.value) return "USD";
            return cart.value.total.currency
        });
        const shipping = computed(() => {
            if (!cart.value) {
                return "To be determined";
            }
            return useFormatMoney(cart.value.shipping)

        });

        const province = computed(() => {
            // if (!cart.value) return "";
            // return cart.value.attributes.find(a => a.key === "province")?.value || "";
        });

        const newsletter = computed(() => {
            // if (!cart.value) return false;
            // return cart.value.attributes.find(a => a.key === "newsletter")?.value === "true";
        });

        const quantity = computed(() => {
            if ((cart.value?.items||[]).length == 0) return 0;
            return cart.value?.items.reduce(
                (total, item) => total + item.quantity, 0)
        });

        const cartId = computed(() => {
            if (!cart?.value) return "";
            return cart.value.id;
        });

        const lines = computed<CartItem[]>(() => {
            if (!cart.value) return [];
            return cart.value.items
        });

        const sellerHandle = computed(() => {
            if (!cart.value || !lines.value[0]) return "";
            return lines.value[0].shop_handle
        });

        const currency = computed(() => {
            if (!cart.value) return "USD";
            return cart.value.total.currency
        })

        const totalAmount = computed(() => {
            if (!cart.value) return 0;
            return cart.value?.total.amount / 100
        })

        return {
            quantity, cart, clientSecret,
            getCart, refreshCart, reset, addToCart, removeFromCart, updateDiscountCode, updateLineQuantity,
            validatePhone,
            cartId, lines, total, sellerHandle, currency, totalAmount, newsletter, deliveryAddress, province,
            subTotal, shipping, discount, cartCurrency
        }

    },
    {
        persist: {
            storage: persistedState.localStorage,
        }
    }
)